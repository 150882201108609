<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signup-on': this.state == 'signup'
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #e9ebf5;"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img
              src="media/logos/logo-letter-1.png"
              class="max-h-100px"
              alt=""
            />
            <br />
            <img src="media/logos/logo-dark.png" class="max-h-100px" alt="" />
          </a>
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h2-lg"
            style="color: #000;"
          >
            Welcome, Onboard
          </h3>
        </div>
        <!-- <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div> -->
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div v-if="valid_link" class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div v-if="status == 0" class="login-form login-signup">
            <div v-if="company_id != 0">
            <form class="form" novalidate="novalidate" id="kt_onboard_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Welcome to AskCoda
                </h3>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Email</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  {{ email }}
                </div>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Role</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  {{ role || not_assign }}
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >First Name</label
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    placeholder="First Name"
                    name="first_name"
                    ref="first_name"
                    v-model="first_name"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Last Name</label
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    placeholder="Last Name"
                    name="last_name"
                    ref="last_name"
                    v-model="last_name"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Password</label
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    placeholder="Password"
                    name="password"
                    ref="password"
                    v-model="password"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Confirm Password</label
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="password"
                    placeholder="Confirm password"
                    name="cpassword"
                    ref="cpassword"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Term Duration</label
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="date"
                    placeholder="Term Duration"
                    name="term_duration"
                    ref="term_duration"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  type="button"
                  ref="kt_login_signup_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                  v-on:click="onSubmitOnBoard()"
                >
                  Update
                </button>
              </div>
            </form>
            </div>
            <div v-else class="login-form login-signup">
              <form class="form" novalidate="novalidate">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Welcome to AskCoda
                </h3>
                <h4>
                  Your email is verified. You are on onboard.
                </h4>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  type="button"
                  ref="kt_login_signup_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                  v-on:click="goToLogin()"
                >
                  Login
                </button>
              </div>
            </form>
            </div>
          </div>

          <div v-else class="login-form login-signup">
            <form class="form" novalidate="novalidate">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Welcome to AskCoda
                </h3>
                <h4>
                  You are already onboard, Please go to login.
                </h4>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  type="button"
                  ref="kt_login_signup_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                  v-on:click="goToLogin()"
                >
                  Login
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
        </div>

        <div v-else class="d-flex flex-column-fluid flex-center hide">
          <div class="login-form login-signup">
            <form class="form" novalidate="novalidate">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Welcome to AskCoda
                </h3>
                <h4>
                  This is not valid link or your token is expired,<br />
                  please try again.
                </h4>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  type="button"
                  ref="kt_login_signup_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                  v-on:click="goToLogin()"
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import axios from "axios";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import EventBus from "@/core/services/store/event-bus";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGOUT, VERIFY_USER, VERIFY_COMPANY_ADMIN } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "onboard",
  data() {
    return {
      state: "signup",
      company_type: "Small Enterprise",
      user_id: "",
      // Remove this dummy login info
      first_name: "",
      last_name: "",
      password: "",
      role: "",
      email: "",
      not_assign: "NA",
      status: 1,
      company_id: 0,
      valid_link: 0
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    }
  },

  beforeMount() {
    //if user is not logged in
    // if (Object.keys(this.currentUser).length != 0) {
    //   this.redirectToDashboard();
    // }
  },
  mounted() {
    let context = this;

    EventBus.$on("USER_VERIFY", function(payLoad) {
      if (payLoad) {
        context.$router.push({ name: "login" });
      }
    });

    EventBus.$on("COMPANY_ADMIN_VERIFY", function(payLoad) {
      if (payLoad) {
        context.$router.push({ name: "login" });
      }
    });

    context.user_id = this.$route.params.user_id;
      
    axios({
      method: "get",
      url: "member-data/" + context.user_id,
      baseURL: process.env.VUE_APP_ACBACK_URL
    })
      .then(function(result) {

        if (result.data.data) {
          context.email = result.data.data.email;
          context.first_name = result.data.data.first_name;
          context.last_name = result.data.data.last_name;
          context.password = result.data.data.password;
          context.role = result.data.data.role;
          context.status = result.data.data.status;
          context.company_id = result.data.data.company_id;
          context.valid_link = 1;

          // verify company admin
          if(context.status == 0 && context.company_id == 0){
            context.$store.dispatch(VERIFY_COMPANY_ADMIN, {
              token: context.user_id
            });
          }
        } else {
          Swal.fire({
            title: "Not Valid",
            text: "Please, use proper link for onboard!",
            icon: "error",
            heightAuto: false
          });
        }
        context.initValidation();
      })
      .catch(function() {
        context.initValidation();
      });
    context.initValidation();
  },
  methods: {
    initValidation() {
      let context = this;
      const onboard_form = KTUtil.getById("kt_onboard_form");

      if (context.status == "0") {
        this.fv1 = formValidation(onboard_form, {
          fields: {
            first_name: {
              validators: {
                notEmpty: {
                  message: "First name is required"
                }
              }
            },
            last_name: {
              validators: {
                notEmpty: {
                  message: "Last name is required"
                }
              }
            },
            password: {
              validators: {
                notEmpty: {
                  message: "Password is required"
                }
              }
            },
            cpassword: {
              validators: {
                notEmpty: {
                  message: "Confirm password is required"
                },
                identical: {
                  compare: function() {
                    return onboard_form.querySelector('[name="password"]')
                      .value;
                  },
                  message: "The password and its confirm are not the same"
                }
              }
            }
          },
          plugins: {
            trigger: new Trigger(),
            submitButton: new SubmitButton(),
            bootstrap: new Bootstrap()
          }
        });
      }
    },
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },

    goToLogin() {
      let context = this;
      context.$router.push({ name: "login" });
    },

    onSubmitOnBoard() {
      this.fv1.validate();
      // let context = this;
      this.fv1.on("core.form.valid", () => {
        const password = this.$refs.password.value;
        const first_name = this.$refs.first_name.value;
        const last_name = this.$refs.last_name.value;
        const term_duration = this.$refs.term_duration.value;
        const token = this.$route.params.user_id; //user_id => /welcome/onboarding/:user_id

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        const submitButton = this.$refs["kt_login_signup_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send register request
          this.$store.dispatch(VERIFY_USER, {
            password: password,
            last_name: last_name,
            first_name: first_name,
            term_duration: term_duration,
            token: token
          });
          // .then(() => this.$router.push({ name: "login" }));

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      });

      this.fv1.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
    }
  }
};
</script>
